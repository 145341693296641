import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { A, Flex, H1, H2, Inner, Outer } from '../components/styled';
import { headerHeight } from '../components/theme/measurements';
import BaseLayout from '../components/layout/BaseLayout';
import Box from '../components/Box';
import Footer from '../components/footer';
import Header from '../components/header';

export const pageQuery = graphql`
  query {
    allFaqJson {
      nodes {
        title
        shortTitle
        link
        faq {
          Q
          A
        }
      }
    }
  }
`;

const FAQPage = ({ data }) => {
  const {
    allFaqJson: { nodes },
  } = data;
  return (
    <BaseLayout seoProps={{ title: 'FAQ' }}>
      <Header />
      <Outer mt={headerHeight} bg="white">
        <Inner pt={60} color="darkerGrey" variant="body2">
          <Flex
            id="page-title"
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'baseline']}
            justifyContent="space-between"
          >
            <H1>Frequently Asked Questions</H1>
            <Box color="borderGrey">Last updated October 2020</Box>
          </Flex>
          <Flex color="blue" mt={20} flexWrap="wrap">
            {nodes.map(({ link, title, shortTitle }) => (
              <React.Fragment key={link}>
                <AnchorLink to={`/faq#${link}`}>{shortTitle || title}</AnchorLink>
                <Box
                  mx={10}
                  width="1px"
                  height={16}
                  bg="lightGrey"
                  sx={{ ':last-child': { display: 'none' } }}
                />
              </React.Fragment>
            ))}
          </Flex>
          <Box fontWeight="500" mt={20}>
            Something missing? Please message us directly in the app using the &quot;Support&quot;
            button in the header, or email us at{' '}
            <A href="mailto:support@sprintlio.com" color="blue">
              support@sprintlio.com
            </A>{' '}
            to let us know and we&#39;ll follow up with an answer and post an update here.
          </Box>
          {nodes.map(({ title, link, faq }) => (
            <Box key={link}>
              <Flex
                justifyContent="space-between"
                flexDirection={['column', 'column', 'row']}
                alignItems={['flex-start', 'flex-start', 'baseline']}
                sx={{ a: { whiteSpace: 'nowrap' } }}
              >
                <H2 mt={50} id={link}>
                  {title}
                </H2>
                <AnchorLink to="/faq#page-title">Return to Top</AnchorLink>
              </Flex>
              {faq.map(({ Q: question, A: answer }) => (
                <React.Fragment key={question}>
                  <Box mt={20} fontWeight="600">
                    {question}
                  </Box>
                  <Box>{answer}</Box>
                </React.Fragment>
              ))}
            </Box>
          ))}
        </Inner>
      </Outer>
      <Footer />
    </BaseLayout>
  );
};
FAQPage.propTypes = {
  data: PropTypes.shape({
    allFaqJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
};

export default FAQPage;
